import React from 'react';
import { FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import './ContactPage.css';

const ContactPage = () => {
    return (
        <div className="contact-page">
            <div className="contact-info">
                <h2>Contact Information</h2>
                <p><i className="fas fa-map-marker-alt"></i> Khasra no 918, Plot no 4136</p>
                <p style={{ paddingLeft: '1rem' }}>Nasriganj Road, Rajpur</p>
                <p style={{ paddingLeft: '1rem' }}>Bihar (802219)</p>
                <p><i className="fas fa-phone-alt"></i> <a href="tel:+916200393554">+91 62003 93554</a></p>
                <p><i className="fas fa-envelope"></i> <a href="mailto:devanand@khelodilse.com">devanand@khelodilse.com</a></p>
            </div>

            <div className="map">
                <h2>Find Us</h2>
                <div className="map-container">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.800646958866!2d84.21123577645693!3d25.074745077790038!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x398da76c16fba25d%3A0xd58d35d39fdabee6!2sKhelo%20Dil%20Se!5e0!3m2!1sen!2sin!4v1682066994250!5m2!1sen!2sin"
                        style={{ border: '0' }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Khelo Dil Se Location"
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;
