import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer>
            <div className="footer-container">
                <div className="social-media">
                    <a href="https://www.facebook.com/khelodilse.rajpur/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
                    <a href="https://www.instagram.com/khelodilse.rajpur/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                    <a href="https://twitter.com/khelodilse_/" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                </div>
                <div className="legal">
                    <p>© 2022 Khelo Dil Se. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
