import React from 'react';
import dns from '../assets/images/dns.jpeg';
import ms from '../assets/images/ms.jpeg';
import './AboutPage.css';

const AboutPage = () => {
    return (
        <div className="about-page">
            <div className="store-history">
                <h2>Our Story</h2>
                <p>
                    Khelo Dil Se was founded in 2022 by Devanand Singh, a sports enthusiast who
                    noticed a lack of high-quality sports equipment and apparel in the local
                    market. Devanand's's passion for sports and dedication to the community inspired
                    him to open a store that offers a wide range of products for various sports
                    at competitive prices.
                </p>
            </div>
            <div className="mission">
                <h2>Our Mission</h2>
                <p>
                    Our mission is to provide our customers with the best selection of sports
                    equipment and apparel, along with exceptional customer service and expert
                    advice. We are committed to supporting our local community and promoting
                    healthy, active lifestyles.
                </p>
            </div>
            <div className="team">
                <h2>Meet Our Team</h2>
                <div className="team-member">
                    <img src={dns} alt="Devanand Singh" />
                    <p>Devanand Singh</p>
                    {/*<p>Owner</p>*/}
                </div>
                <div className="team-member">
                    <img src={ms} alt="Mritunjay Singh" />
                    <p>Mritunjay Singh</p>
                    {/*<p>Owner</p>*/}
                </div>
            </div>
        </div>
    );
};

export default AboutPage;
