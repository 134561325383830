import React from 'react';
import './Navigation.css';

const Navigation = ({ mobile }) => {
    const navClassName = mobile ? 'navigation-mobile' : 'navigation';

    return (
        <nav>
            <ul className={navClassName}>
                <li><a href="/">Home</a></li>
                <li><a href="/about">About</a></li>
                <li><a href="/contact">Contact</a></li>
            </ul>
        </nav>
    );
};

export default Navigation;
