import React, { useState } from 'react';
import Navigation from './Navigation';
import './Header.css';
import logo from '../assets/images/kds_logo.png';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header>
            <div className="logo-container">
                <a href="/"><img src={logo} alt="Khelo Dil Se" /></a>
                <h1 className="store-name">Khelo Dil Se</h1>
            </div>
            {/* Add the Navigation component for the desktop view here */}
            <Navigation />
            <div className="hamburger-menu" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
                {menuOpen && <Navigation mobile />} {/* Pass the mobile prop */}
            </div>
        </header>
    );
};

export default Header;
