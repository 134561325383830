import React from 'react';
import kds_store from '../assets/images/kds_store.jpeg';
import './HomePage.css';

const HomePage = () => {
    return (
        <div className="home-page">
            <div className="hero-section">
                <img src={kds_store} alt="Khelo Dil Se Store" />
            </div>
            <div className="store-description">
                <h2>Welcome to Khelo Dil Se</h2>
                <p>
                    We offer a wide range of sports equipment and apparel for
                    various sports and activities. We are dedicated to providing high-quality
                    products at competitive prices. Visit our store in Rajpur to
                    discover our selection.
                </p>
            </div>
            <div className="promotions">
                <h2>Latest News</h2>
                <p>
                    We now carry the latest collection of sports shoes from top brands. Visit our
                    store to find the perfect pair for any sport.
                </p>
            </div>
        </div>
    );
};

export default HomePage;
